<script setup lang="ts">
import Navigation from '@/components/custom/Navigation.vue';
import Footer from '@/components/custom/Footer.vue';

const route = useRoute();
const hideNavigation = computed(() => route.meta.hideNavigation)
const hideFooter = computed(() => route.meta.hideFooter)
const fullWidth = computed(() => route.meta.fullWidth)
</script>

<template>
  <div class="min-h-screen flex flex-col">
    <template v-if="!hideNavigation">
      <ClientOnly>
        <Navigation />
      </ClientOnly>
    </template>
    <main class="w-full grow mt-16">
      <div v-if="fullWidth">
        <slot />
      </div>
      <div class="flex flex-col gap-6 p-2 lg:py-10 mx-auto max-w-7xl" v-else>
        <slot />
      </div>
    </main>
    <Footer v-if="!hideFooter" />
  </div>
</template>
